* {
  outline: none;
  -ms-overflow-style: none !important;
  /* IE and Edge */
  scrollbar-width: none !important;

  /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }
}

html,
body,
#root {
  font-family: "Satoshi";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

body {
  margin: 0;
  box-sizing: border-box;
}

.app {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-flow: column;
  flex-direction: column;
  z-index: 1;
  background-size: cover;
  font-family: "Satoshi";
  overflow: hidden;
  transition: all ease 0.33ms;
  letter-spacing: 2px;
}

.aalto-card {
  max-width: 833px;

  .card-header {
    width: 100%;
    min-height: 33px;
    margin-bottom: 10px;

    h5 {
      font-weight: 600 !important;
    }
  }

  .help-text {
    margin: 10px 0px;
  }

  .data-row {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 12px 0px;
  }

  .data-row-centered {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 12px 0px;

    .marginedBtn {
      margin: 0.3rem;
    }
  }
}

.mama-pairs {
  display: flex;
  align-items: center;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

input[type="number"] {
  -moz-appearance: textfield !important;
}

a:hover svg {
  color: inherit;
}

#treasury-dashboard-view .hero-metrics {
  text-align: start !important;
}

.header-span svg {
  margin-right: 8px;
  width: 32px !important;
  height: 32px !important;
}

.header-span {
  &:last-of-type svg {
    margin-right: 0px;
  }
}

@media (min-width: 960px) {
  .header-span {
    height: 36px;
  }

  .footer-span {
    height: 22px;
  }
}

.footer-span svg {
  margin-right: 8px;
  width: 22px !important;
  height: 22px !important;
}

.footer-span {
  font-size: 14px;
  font-weight: 500;
}

.dapp-menu-links-top {
  display: flex;
  align-items: center;

  #navbarNav {
    display: flex;
  }
}

.aalto-primary-button {
  background: #020de0 !important;
  color: #fff !important;
  border-radius: 228px !important;
  font-size: 16px !important;
  padding: 2px 16px !important;
}

.aalto-rec-button {
  background: #020de0 !important;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  min-width: 100px !important;
  margin-bottom: 6px !important;
}

.aalto-rec-button.big {
  font-size: 16px !important;
  line-height: 22px !important;
  padding: 14px 30px !important;
  font-weight: 700 !important;
}

.aalto-rec-button.Mui-disabled {
  background: #020de0 !important;
  opacity: 0.4;
  color: #fff !important;
  border-radius: 4px !important;
  font-size: 16px !important;
  min-width: 100px !important;
}

.aalto-transparent-button {
  background: transparent !important;
  color: #fff !important;
  border-radius: 228px !important;
  font-size: 16px !important;
  padding: 6px 16px !important;
  margin-right: 6px !important;
}

.active.aalto-transparent-button {
  background: #020de0 !important;
}

.checkbox-round {
  width: 1.3em;
  height: 1.3em;
  background-color: white;
  border-radius: 50%;
  vertical-align: middle;
  border: 1px solid #ddd;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;
}

.checkbox-round:checked {
  background-color: #020de0;
  border: 4px solid #ddd;
}

.reward-img {
  max-width: 42px;
  max-height: 42px;
}

.header-span svg.metamask-icon {
  position: absolute;
  width: 20px !important;
  height: 20px !important;
  top: 20px;
  left: -6px;
}

.aalto-txt {
  color: rgb(3, 225, 208);
}

.pointer {
  cursor: pointer;
}

.details-chevron {
  color: inherit !important;
}

.aalto-card-action-area-inp-wrap .aalto-card-action-input {
  width: 100%;
  border-radius: 4px;
  outline: none;
  background: #000829;
  padding-right: 10px;
  padding-left: 10px;
}
