#treasury-dashboard-view {
  overflow-y: hidden;
  overflow-x: hidden;
  height: 100%;
  // width: 100%;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow-y: auto;
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
  &::-webkit-scrollbar {
    display: none !important;
  }

  .aalto-card {
    max-width: unset !important;
    width: 100%;
    margin: auto;
    .card-header {
      margin-bottom: 1rem;
    }
    &.mama-chart-card {
      .chart-card-header {
        padding: 0px 20px !important;
        min-width: 300px;
      }
      height: 355px;
    }
  }
  .hero-metrics {
    width: 100%;
    margin-bottom: 23px;
    text-align: center;
    .aalto-card {
      max-width: unset !important;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-evenly;
      h5 {
        color: #fff;
        font-weight: bold;
      }
      div.metric {
        margin: 3px;
        min-width: 140px;
        width: 30%;
        padding-top: 16px;
        padding-bottom: 16px;
      }
      div.metric-div-4 {
        margin: 3px;
        min-width: 140px;
        width: calc(25% - 12px);
        padding-top: 16px;
        padding-bottom: 16px;
      }
      div.metric-div-1 {
        margin: 3px;
        min-width: 140px;
        width: 100%;
        padding-top: 16px;
        padding-bottom: 16px;
      }
    }
  }
  .data-grid {
    overflow: hidden;
  }
}
.app.tablet #treasury-dashboard-view .hero-metrics .aalto-card div.metric {
  width: 100% !important;
}

.app.light #treasury-dashboard-view .hero-metrics .aalto-card div.metric {
  background-color: #fcfdff;
}

.smaller {
  .aalto-card {
    width: 97%;
  }
  .hero-metrics .aalto-card {
    > .MuiBox-root {
      flex-direction: column;
      align-items: flex-start;
      flex-wrap: wrap;
    }
    div.metric {
      text-align: left !important;
    }
    div.metric-2 {
      text-align: left !important;
    }
    div.metric-div-1 {
      text-align: left !important;
    }
  }
}

.mobile {
  div.metric {
    white-space: nowrap;
    min-width: 100% !important;
    text-align: left !important;
    &.market,
    &.price,
    &.wsoprice,
    &.circ,
    &.bpo,
    &.index {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  div.metric-div-1 {
    white-space: nowrap;
    min-width: 100% !important;
    text-align: left !important;
    &.market,
    &.price,
    &.wsoprice,
    &.circ,
    &.bpo,
    &.index {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}

.app:not(.mobile) .runway {
  margin: 0 auto !important;
}

.very-small {
  .hero-metrics .aalto-card {
    > .MuiBox-root {
      flex-direction: column;
      justify-content: center;
    }
  }
}

.aalto-card .aalto-card-action-area-inp-wrap .aalto-card-action-input {
  width: 100%;
  border-radius: 4px;
  outline: none;
  background: #000829;
  padding-right: 10px;
  padding-left: 10px;
}

.CustomRightText-upper {
  font-size: 20px;
}

.CustomRightText-lower {
  font-size: 16px;
}
